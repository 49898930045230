export const environment = {
    production: true,
    hmr       : false,
    api_url: 'https://api.e.cct.tn',
    recaptchaSiteKey: '6LfZH-wbAAAAAGO1fLWvStExn6IYO3cXg9xjztp9',
    SENTRY_AUTH_TOKEN: '502331f57af97f287f679d887b69145fab397241a0700ef7fc3613f8d5ffbe69',
    build: '14-01-2025-17-18-23',
    version: require('../../package.json').version

};
