import { ErrorService } from './../_helpers/_error-service/error.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/main/_models/User';
import swal from 'sweetalert2';

import { SharedMenuObserverService } from '../_services/shared-menu-observer.service';
import { Filter } from '../users/_models/filter';
import { UserService } from '../users/_services/user.service';
import { MailService } from './_services/mail.service';
import { locale as english } from './i18n/en';
import { locale as frensh } from './i18n/fr';
import { Mail } from './Model/Mail';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class SendMailComponent implements OnInit {
    page = 1;
    size = 10;
    totalPages = 0;
    filter: Filter = new Filter();
    dataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
    users: Array<User> = new Array<User>();
    displayedColumns: string[] = ['Actions', 'cin', 'nom', 'prenom', 'email', 'goverment', 'solde', 'status'];
    end = '';
    start = '';
    selectedAll = false;
    selectedIds: string[] = [];
    countSelected = 0;
    initCountSelected = 0;
    public Editor = ClassicEditor;
    
    listVillefilter: string[] = [];
    item = 0;
    collapseFilter = false;
    listVille: string[] = [
        'Ariana',
        'Béja',
        'Ben Arous',
        'Bizerte',
        'Gabès',
        'Gafsa',
        'Jendouba',
        'Kairouan',
        'Kasserine',
        'Kébili',
        'Le Kef',
        'Mahdia',
        'La Manouba',
        'Médenine',
        'Monastir',
        'Nabeul',
        'Sfax',
        'Sidi Bouzid',
        'Siliana',
        'Sousse',
        'Tataouine',
        'Tozeur',
        'Tunis',
        'Zaghouan',
    ];
    newMail : Mail = new Mail();
    toggleListUsers = true;
       
  constructor(private translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private userService: UserService,
    private datePipe: DatePipe,
    private _errorService:ErrorService,
    private mailServices: MailService,
    public matDialogRef: MatDialogRef<SendMailComponent>,
    private translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.translationLoader.loadTranslations(english, frensh);
        this.getListUsers();
  }

    toggleFilterCorp(): void {
        this.collapseFilter = !this.collapseFilter;
    }

    openToggleListUsers():void{
        this.toggleListUsers = !this.toggleListUsers;
    }
  reset(): void {
    this.filter = new Filter();
    this.filter.status = null;
    this.filter.contribution = null;
    this.start = '';
    this.end = '';
    this.listVillefilter.forEach(index => this.deleteItem(index));
    this.getListUsers();
}

    getVilleFilter(event: any): void {
        if (event.target.checked === true) {
            this.listVillefilter[this.item] = event.target.value;
            this.item++;
        } else {
            this.deleteItem(event.target.value);
            this.item--;
        }
    }

    sucessAlert(): void {
        let title = "";
        let data = "";
        this.translate.get('SELECTEVENT.OPERATIONSUCCESS').subscribe((text: string) => { title = text });
        this.translate.get('SELECTEVENT.EMAILSENDED').subscribe((text: string) => { data = text });
        swal.fire({
            title: title,
            text: data,
            icon: 'success',
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: '#68a45b',
            confirmButtonText: 'Fermer',
        });
    }

    alertWarning(title, data): void {
        swal.fire({
            title: title,
            text: data,
            icon: 'warning',
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: 'Fermer',
            confirmButtonColor: '#d53a3a',
        });
    }

    deleteItem(element: any): void {
        const index: number = this.listVillefilter.indexOf(element);
        if (index !== -1) {
            this.listVillefilter.splice(index, 1);
        }
    }

    /**
    * get list users
    */
   getListUsers(): void {
    this.collapseFilter = false
    this.filter.date_inscription_start = this.datePipe.transform(this.start, 'yyyy-MM-dd');
    this.filter.date_inscription_end = this.datePipe.transform(this.end, 'yyyy-MM-dd');
    this.userService.GetListUser(this.page, this.size, this.filter, null)
        .subscribe((data) => {
          this.users =  data.content;
          this.dataSource.data = data.content;
          this.totalPages = data.totalPages;
          this.initCountSelected = data.totalElements;
          if(this.collapseFilter){
            this.collapseFilter = false
          }
          if(this.selectedAll){
              this.dataSource.data.forEach(element => element.selected = true);
          }else{
              this.dataSource.data.forEach(element => element.selected = false);
          }
          if(this.selectedAll && this.selectedIds.length > 0){
              this.dataSource.data.forEach(element => {
                  if(this.selectedIds.includes(element.publicId))
                  element.selected = false;
              });
          }

        },err=>{
            let userListError = "";
            this.translateService.get('SELECTEVENT.LISTUSERERROR').subscribe((text: string) => { userListError = text })
            this._errorService.getErrorType(userListError);
            });
    }

    getInvitedList(publicId, user): void {
      if(this.selectedIds.includes(publicId)){
          this.selectedIds.splice(this.selectedIds.indexOf(publicId), 1)
          if(this.selectedAll)
              this.countSelected ++;
          else
              this.countSelected --;
      }else{
          this.selectedIds.push(publicId)
          if(this.selectedAll)
              this.countSelected --;
          else
              this.countSelected ++;

      }
  }


  selectAll(): void {
      if(this.selectedAll){
          this.dataSource.data.forEach(element => element.selected = true);
          this.countSelected = 0;
      }else{
          this.dataSource.data.forEach(element => element.selected = false);
          this.countSelected = this.initCountSelected;
      }
      this.dataSource.data.forEach(element => element.selected = !element.selected);
      this.selectedAll = !this.selectedAll;
      this.selectedIds = [];
  }

  SendMail():void{
    let warning = "";
    let somethingWrong = "";
    let errorSelectUsers = "";
    this.translateService.get('SELECTEVENT.EMAILWARNING').subscribe((text: string) => { warning = text });
    this.translateService.get('SELECTEVENT.SOMETHINGWENTWRONG').subscribe((text: string) => { somethingWrong = text });
    this.translateService.get('SELECTEVENT.CHOOSEONEUSER').subscribe((text: string) => { errorSelectUsers = text });
    this.newMail.usersPublicIds = this.selectedIds
    if(this.newMail.mailContent == "" || this.newMail.subject == ""){
        this.alertWarning(somethingWrong, warning)
    }else if (this.newMail.usersPublicIds.length < 1){
        this.alertWarning(somethingWrong, errorSelectUsers)
    }else {
        this.matDialogRef.close(true)
        this.mailServices.SendMails(this.newMail, true)
        .subscribe((response)=>{
            this.sucessAlert();
        },err=>{
            let sendMailError = "";
            this.translateService.get('SELECTEVENT.EMAILERROR').subscribe((text: string) => { sendMailError = text })
            this._errorService.getErrorType(sendMailError);
        })
    }
  }

  first(): void {
      this.setPage(1);
  }

  prev(): void {
      this.setPage(Math.max(1, this.page - 1));
  }

  next(): void {
      this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last(): void {
      this.setPage(this.totalPages);
  }

  setPage(val: number): void {
      this.page = val;
      this.getListUsers();
  }

  isFirst(): boolean {
      return this.page === 1;
  }

  isLast(): boolean {
      return this.page === this.totalPages;
  }

  changepage(num: number): void {
    this.page = num;
    this.getListUsers();
  }
}
