export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'ADMIN': 'Administrator',
            'USER': 'User',
            'ADHERANT': 'Member',
            'EXPORTASSURANCE': 'List of insurance contracts',
            'GESTIONEVENT': 'Event management',
            'LISTEVENT': 'Event list',
            'GESTIONUSER': 'User management',
            'GESTIONENTREPRISE': 'Company management',
            'PROFILE': 'My profile',
            'LISTPAIEMENT': 'List of payments',
            'COTISATION': 'My contribution',
            'GESTIONCONVENTION': 'Agreements and shared documents',
            'LISTCONVENTION': 'Agreements and shared documents',
            'ASSURANCE': 'Insurance',
            'ACTUALITE': 'News',
            'TRACABILITY': 'Traceability',
            'GESTIONCOTISATION': 'Membership management',
            'LISTTRAINEE': "List of trainees",
            'LISTREQUEST': "List of requests",
            'TRAINEE': 'Trainees',
            "PARAMETRE":"Setting",
            'LISTDIPLOME': "Liste des diplômes",
            'STAGE': "Cadre de stage",
            "DOCS":"Gestion des documents",
            "MODS":"Gestion des modules",
            'REQUESTLIST': 'Liste des demandes',
            'TRAINEELIST': 'Liste des stagiaires',
        }
    }
};
