export class Notification{
    publicId = "";
    title = "";
    description = "";
    extraInfo = null;
    dateNotification = "";
    status = "";
    typeEntity: string;
}

export class NotificationDto{
    notifications: Notification[]= [];
    count:         number=0;
}

