import { User } from 'app/main/_models/User';
import { indexOf } from 'lodash';
import { IssueGitlab } from './../_services/gitlab/_models/IssueGitlab';
import { GitlabService } from './../_services/gitlab/gitlab.service';
import { ErrorService } from './_error-service/error.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, onErrorResumeNext, throwError } from 'rxjs';
import { catchError, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import swal from 'sweetalert2';

import { AuthenticationService } from '../../authentication/_services/authentication.service';
import { locale as english } from './i18n/en';
import { locale as frensh } from './i18n/fr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    newIssue: IssueGitlab = new IssueGitlab();
    constructor(private authenticationService: AuthenticationService, private router: Router,
        private translate: TranslateService,
        private _matDialog: MatDialog,
        private _errorService: ErrorService,
        private _gitService: GitlabService,
        private translationLoader: FuseTranslationLoaderService) {
        this.translationLoader.loadTranslations(english, frensh);
        
    }

    alertError(errorOperation, error: string, codeError): void {
        let descriptionProblem = "";
        let reportProblem = "";
        let errorText = "";
        let close = "";
        let expiredSession = "";
        let reconnect = "";
        this.translate.get('INTERCEPTOR.ERROR').subscribe((text: string) => { errorText = text });
        this.translate.get('INTERCEPTOR.CLOSE').subscribe((text: string) => { close = text });
        this.translate.get('INTERCEPTOR.REPORTPROBLEM').subscribe((text: string) => { reportProblem = text });
        this.translate.get('INTERCEPTOR.DESCRIPTIONPROBLEM').subscribe((text: string) => { descriptionProblem = text });
        this.translate.get('INTERCEPTOR.EXPIREDSESSION').subscribe((text: string) => { expiredSession = text });
        this.translate.get('INTERCEPTOR.RECONNECT').subscribe((text: string) => { reconnect = text });
        
        swal.fire({
            title: codeError == 417 ? expiredSession : errorText,
            text: codeError == 417 ? reconnect : errorOperation,
            icon: 'error',
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonColor: '#d94242',
            confirmButtonText: close,
            inputLabel: reportProblem,
            input: 'text',
            inputPlaceholder: descriptionProblem,
            inputAttributes: {
                autocapitalize: 'off',
            },
        }).then((result)=>{
            if(result.isConfirmed || result.isDismissed){
                let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                
                if( error?.includes('Refresh token is not in database!')){
                    localStorage.removeItem('currentUser')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('token')
                    this.router.navigate(['/auth/login'])
                }
                if(result.value){
                    this.newIssue.nom = currentUser.firstName + " " + currentUser.lastName;
                    this.newIssue.title = errorOperation
                    this.newIssue.description =  error + " - " + result.value
                    this._gitService.pushIssue(this.newIssue).subscribe()
                }
                
            }
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(request)
            .pipe(
                catchError(error => {
                    if (this._checkTokenExpiryErr(error) && localStorage.getItem('refreshToken')) {
                        return this.authenticationService.refreshToken().pipe(
                            tap(({token, refreshToken}) => {
                                localStorage.setItem('token', token);
                                localStorage.setItem('refreshToken', refreshToken);
                            }),
                            switchMap((resp) => {
                                return next.handle(this.injectToken(request)).pipe(retry(1));
                            })
                        );
                    } else {
                        //this.authenticationService.logout();
                        if(!window.location.pathname.includes('auth/login') && window.location.pathname != '/' ){
                            this._matDialog.closeAll()
                            this._errorService.onChangeErrorType()
                            .subscribe((errorDescription:string) => {
                                if(errorDescription != ''){
                                    this.alertError(errorDescription, error?.message + " | details: "+error?.error?.message, error?.status);    
                                }
                            });
                        }else{
                            //this.router.navigate(['/auth/login']);
                        }
                        return throwError(error);
                    }
                }), retry(0));
    }


    private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
        return (
            error.status &&
            (error.status === 401) )
    }

    private injectToken(request: HttpRequest<any>): any {
        const token = localStorage.getItem('token');
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
}
