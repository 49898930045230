import { FuseNavigation } from '@fuse/types';

export const navigationAdmin: FuseNavigation[] = [
    {
        id: 'admin',
        title: 'Admin',
        translate: 'NAV.ADMIN',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'actuality',
                title: 'actuality',
                translate: 'NAV.ACTUALITE',
                type: 'item',
                icon: 'home',
                url: '/profile/actuality'
            },
            {
                id: 'users',
                title: 'Gestion des utilisateurs',
                translate: 'NAV.GESTIONUSER',
                type: 'item',
                icon: 'people',
                url: '/profile/users'
            },
            {
                id: 'gestion-evenement',
                title: 'Gestion des événements',
                translate: 'NAV.GESTIONEVENT',
                type: 'item',
                icon: 'event_note',
                url: '/profile/meetings'
            },
            {
                id: 'compagnie',
                title: 'Gestion des sociétés',
                translate: 'NAV.GESTIONENTREPRISE',
                type: 'item',
                icon: 'domain',
                url: '/profile/company'
            },
            {
                id: 'paiement',
                title: 'Liste des paiements',
                translate: 'NAV.LISTPAIEMENT',
                type: 'item',
                icon: 'attach_money',
                url: '/profile/payment'
            },
            {
                id: 'export_assurance',
                title: 'export_assurance',
                translate: 'NAV.EXPORTASSURANCE',
                type: 'item',
                icon: 'library_books',
                url: '/profile/insurance/all-contracts'
            },
            {
                id: 'conventions',
                title: 'Conventions',
                translate: 'NAV.GESTIONCONVENTION',
                type: 'item',
                icon: 'folder_shared',
                url: '/profile/conventions'
            },
           /*  {
                id: 'tracability',
                title: 'Tracability',
                translate: 'NAV.TRACABILITY',
                type: 'item',
                icon: 'phonelink',
                url: '/profile/tracability'
            }, */
            {
                id: 'userGroup',
                title: 'Utilisateur',
                translate: 'NAV.ADHERANT',
                type: 'group',
                icon: 'apps',
            },
            {
                id: 'profile',
                title: 'Profil',
                translate: 'NAV.PROFILE',
                type: 'item',
                icon: 'person_pin',
                url: '/profile/info'
            },
            {
                id: 'cotisation',
                title: 'Cotisation',
                translate: 'NAV.COTISATION',
                type: 'item',
                icon: 'monetization_on',
                url: '/profile/cotisation'
            },
            {
                id: 'contrat',
                title: 'Assurance',
                translate: 'NAV.ASSURANCE',
                type: 'item',
                icon: 'library_books',
                url: '/profile/insurance/list'
            },
            {
                id: 'stagiaire',
                title: 'Stagiaire',
                translate: 'NAV.TRAINEE',
                type: 'item',
                icon: 'supervisor_account',
                url: '/profile/trainee'
            },
        ]
    }
];
export const navigation: FuseNavigation[] = [
    {
        id: 'user',
        title: 'Utilisateur',
        translate: 'NAV.ADHERANT',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'actuality',
                title: 'Actualite',
                translate: 'NAV.ACTUALITE',
                type: 'item',
                icon: 'home',
                url: '/profile/actuality'
            },
            {
                id: 'gestion-evenement',
                title: 'Liste des événements',
                translate: 'NAV.LISTEVENT',
                type: 'item',
                icon: 'event_note',
                url: '/profile/meetings'
            },
            {
                id: 'profile',
                title: 'Profil',
                translate: 'NAV.PROFILE',
                type: 'item',
                icon: 'person_pin',
                url: '/profile/info'
            },
            {
                id: 'cotisation',
                title: 'Cotisation',
                translate: 'NAV.COTISATION',
                type: 'item',
                icon: 'monetization_on',
                url: '/profile/cotisation'
            },
            {
                id: 'contrat',
                title: 'Assurance',
                translate: 'NAV.ASSURANCE',
                type: 'item',
                icon: 'library_books',
                url: '/profile/insurance/list'
            },
            {
                id: 'conventions',
                title: 'Conventions',
                translate: 'NAV.LISTCONVENTION',
                type: 'item',
                icon: 'insert_drive_file',
                url: '/profile/conventions'
            },
            {
                id: 'stagiaire',
                title: 'Stagiaire',
                translate: 'NAV.TRAINEE',
                type: 'item',
                icon: 'supervisor_account',
                url: '/profile/trainee'
            }
        ]
    }
];

export const navigationSuperAdmin: FuseNavigation[] = [
    {
        id: 'user',
        title: 'Utilisateur',
        translate: 'Super Admin',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'actuality',
                title: 'Actualite',
                translate: 'NAV.ACTUALITE',
                type: 'item',
                icon: 'home',
                url: '/profile/actuality'
            },
            {
                id: 'gest-cotisation',
                title: 'Cotisation',
                translate: 'NAV.GESTIONCOTISATION',
                type: 'item',
                icon: 'monetization_on',
                url: '/profile/gestion-cotisation'
            },
            {
                id: 'userGroup',
                title: 'Utilisateur',
                translate: 'NAV.ADMIN',
                type: 'group',
                icon: 'apps',
            },
            {
                id: 'users',
                title: 'Gestion des utilisateurs',
                translate: 'NAV.GESTIONUSER',
                type: 'item',
                icon: 'people',
                url: '/profile/users'
            },
            {
                id: 'gestion-evenement',
                title: 'Gestion des événements',
                translate: 'NAV.GESTIONEVENT',
                type: 'item',
                icon: 'event_note',
                url: '/profile/meetings'
            },
            {
                id: 'compagnie',
                title: 'Gestion des entreprises',
                translate: 'NAV.GESTIONENTREPRISE',
                type: 'item',
                icon: 'domain',
                url: '/profile/company'
            },
            {
                id: 'paiement',
                title: 'Liste des paiements',
                translate: 'NAV.LISTPAIEMENT',
                type: 'item',
                icon: 'attach_money',
                url: '/profile/payment'
            },
            {
                id: 'export_assurance',
                title: 'export_assurance',
                translate: 'NAV.EXPORTASSURANCE',
                type: 'item',
                icon: 'library_books',
                url: '/profile/insurance/all-contracts'
            },
            {
                id: 'conventions',
                title: 'Conventions',
                translate: 'NAV.GESTIONCONVENTION',
                type: 'item',
                icon: 'folder_shared',
                url: '/profile/conventions'
            },
            {
                id: 'profile',
                title: 'Profil',
                translate: 'NAV.PROFILE',
                type: 'item',
                icon: 'person_pin',
                url: '/profile/info'
            },
            {
                id: 'stagiaire',
                title: 'Stagiaire',
                translate: 'NAV.TRAINEE',
                type: 'collapsable',
                icon: 'supervisor_account',
                children: [
                    {
                        id: 'params',
                        title: 'Paramétrage',
                        translate: 'NAV.REQUESTLIST',
                        type: 'item',
                        icon: 'settings',
                        url: '/profile/trainee',
                    },
                    {
                        id: 'params',
                        title: 'Paramétrage',
                        translate: 'NAV.TRAINEELIST',
                        type: 'item',
                        icon: 'settings',
                        url: '/profile/stagiaire',
                    },
                ]
            },
            {
                id: 'params',
                title: 'Paramétrage',
                translate: 'NAV.PARAMETRE',
                type: 'collapsable',
                icon: 'settings',
                children: [
                    {
                        id: 'params',
                        title: 'Paramétrage',
                        translate: 'NAV.LISTDIPLOME',
                        type: 'item',
                        icon: 'settings',
                        url: '/profile/config/list-diplome',
                    },
                    {
                        id: 'params',
                        title: 'Paramétrage',
                        translate: 'NAV.STAGE',
                        type: 'item',
                        icon: 'settings',
                        url: '/profile/config/frais-responsable',
                    },
                    {
                        id: 'doc',
                        title: 'Documents',
                        translate: 'NAV.DOCS',
                        type: 'item',
                        icon: 'settings',
                        url: '/profile/config/document',
                    },
                    {
                        id: 'mod',
                        title: 'Modules',
                        translate: 'NAV.MODS',
                        type: 'item',
                        icon: 'settings',
                        url: '/profile/config/module',
                    }
                ]
            }
        ]
    }
];

export const navigationTrainee: FuseNavigation[] = [
    {
        id: 'trainee',
        title: 'Utilisateur',
        translate: 'NAV.TRAINEE',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'trainees',
                title: 'trainees',
                translate: 'NAV.LISTREQUEST',
                type: 'item',
                icon: 'storage',
                url: '/profile/trainee/requests'
            },
            {
                id: 'profileTrainee',
                title: 'profileTrainee',
                translate: 'NAV.PROFILE',
                type: 'item',
                icon: 'person_pin',
                url: '/profile/trainee/infos'
            },
        ]
    }
];