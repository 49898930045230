import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../../_models/User';
import {map, mergeMap, tap} from 'rxjs/operators';
import {UserRole} from '../../_models/UserRole';
import {FuseNavigationService} from '../../../../@fuse/components/navigation/navigation.service';
import { DisciplinaryOrder } from 'app/main/profile/users/_models/DisciplinaryOrder';
import {navigation, navigationAdmin, navigationSuperAdmin, navigationTrainee} from '../../../navigation/navigation';
import { TraineeInsert } from 'app/main/_models/trainee';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    protected api_url = environment.api_url;
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
        protected httpClient: HttpClient,
        private _fuseNavigationService: FuseNavigationService,
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        // Register the navigation to the service
        this._fuseNavigationService.register('admin', navigationAdmin);
        this._fuseNavigationService.register('user', navigation);
        this._fuseNavigationService.register('super', navigationSuperAdmin);
        this.updateNavigation();
    }

    private updateNavigation(): void {
        // Set the main navigation as our current navigation
        if(this.hasRole('trainee') || this.hasRole('external')) {
            this._fuseNavigationService.register('trainee', navigationTrainee);
            this._fuseNavigationService.setCurrentNavigation('trainee');
        }else if (this.hasRole('super')) {
            this._fuseNavigationService.setCurrentNavigation('super');
        } else if(this.hasRole('admin')) {
            this._fuseNavigationService.setCurrentNavigation('admin');
        }else {
            this._fuseNavigationService.setCurrentNavigation('user')       }
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public oldhasRole(role: string): boolean {
        const _roles_trainee_user = this.currentUserValue?.roles?.filter((roleDto: UserRole) => roleDto.role.toLowerCase().includes(role.toLowerCase()));
        const _roles = this.currentUserValue?.roles?.filter((roleDto: UserRole) => roleDto.role.toLowerCase().indexOf(role.toLowerCase()) >= 0);
        if (_roles?.length > 0) {
            return true;
        }
        return false;
    }
    public hasRole(role: string): boolean {
        const formattedRole = role.toUpperCase().startsWith('ROLE_') ? role.toUpperCase() : `ROLE_${role.toUpperCase()}`;
        const userRoles = this.currentUserValue?.roles?.map((roleDto: UserRole) => roleDto.role.toUpperCase());
    
        if (!userRoles || userRoles.length === 0) {
            return false; // No roles available
        }
    
        // If the user has both 'ROLE_USER' and 'ROLE_TRAINEE', prioritize 'ROLE_USER'
        if (userRoles.includes('ROLE_USER') && userRoles.includes('ROLE_TRAINEE')) {
            return formattedRole === 'ROLE_USER';
        }
    
        // Check for the specified role
        return userRoles.includes(formattedRole);
    }

    public hasRoleTrainee(): boolean {
        const userRoles = this.currentUserValue?.roles?.map((roleDto: UserRole) => roleDto.role.toUpperCase());
    
        if (!userRoles || userRoles.length === 0) {
            return false; // No roles available
        }
    
        // Check if 'ROLE_TRAINEE' is in the list of roles
        return userRoles.includes('ROLE_TRAINEE');
    }
    

    /**
     * Permettant d'inserer un stagiaire
     * @param insertTrainee
     */
    createManagementTrainee(insertTrainee: TraineeInsert, type: string): Observable<any> {

        let headers = new HttpHeaders();
        headers = headers.append('password',insertTrainee.trainee.password)
        headers = headers.append('type', type)

        return this.httpClient.request<any>('post', `${this.api_url}/api/trainee/add`,
            {
                headers: headers,
                body: insertTrainee
            }
        );
    }

    /**
     * Permettant de modifier un stagiaire
     * @param updateTrainee
     */
    updateManagementTrainee(updateTrainee: TraineeInsert, publicId: string): Observable<any> {

        let headers = new HttpHeaders();

        return this.httpClient.request<any>('put', `${this.api_url}/api/trainee/update/${publicId}`,
            {
                headers: headers,
                body: updateTrainee
            }
        );
    }


    /**
     * Validation les information d'utilisateur et modifier
     *
     * @param sign
     * @param publicId
     * @param token
     *
     */
    authentificationSign(sign: any, publicId: string, token: string): Observable<any> {
        if (sign === null || sign === undefined) {
            throw new Error('Required parameter User was null or undefined.');
        }

        if (publicId === null || publicId === undefined) {
            throw new Error('Required parameter publicId user was null or undefined.');
        }

        const queryParameters = new HttpParams();

        let headers = new HttpHeaders();

        headers = headers.set('Authorization', 'Bearer ' + token);

        return this.httpClient.request<any>('post', `${this.api_url}/api/authentification/sign/${publicId}`,
            {
                body: sign,
                headers: headers,
            }
        );
    }

    /**
     * Permettant de retourner les information d'utilisateur
     *
     * @param cardNumber
     * @param cin
     *
     */
    authentification(cardNumber: number, cin: number, reCaptcha: string): Observable<any> {
        if (cardNumber === null || cardNumber === undefined) {
            throw new Error('Required parameter cardNumber was null or undefined.');
        }

        if (cin === null || cin === undefined) {
            throw new Error('Required parameter cin was null or undefined.');
        }

        if (reCaptcha === null || reCaptcha === undefined) {
            throw new Error('Required parameter reCaptcha was null or undefined.');
        }

        return this.httpClient.request<any>('post', `${this.api_url}/api/authentification/init`,
            {
                body: {
                    cin: cin,
                    cardNumer: cardNumber,
                    reCaptcha: reCaptcha
                }
            }
        );
    }


    /**
     * Permettant de retourner les informations details de lutilisateur courant
     * Permettant de retourner les informations details de lutilisateur courant
     */
    getUserDetails(): Observable<any> {
        const queryParameters = new HttpParams();

        const headers = new HttpHeaders();

        return this.httpClient.request<any>('get', `${this.api_url}/api/user/details`,
            {
                headers: headers,
            }
        );
    }

    /**
     * Permettant de rechercher les lutilisateur par email
     * @param email string
     */
    findUserByEmail(email: string): Observable<any> {
        const queryParameters = new HttpParams();

        const headers = new HttpHeaders();

        return this.httpClient.request<any>('get', `${this.api_url}/api/user/searchByEmail/${email}`,
            {
                headers: headers,
            }
        );
    }

    /**
     * Permettant de retourner les informations details de lutilisateur courant
     */
     getUserByCardNumber(cin:string ,cardNumber: string): Observable<any> {
        const queryParameters = new HttpParams();

        const headers = new HttpHeaders();

        return this.httpClient.request<any>('get', `${this.api_url}/api/user/getByCardNumber/${cin}/${cardNumber}`,
            {
                headers: headers,
            }
        );
    }

    /**
     * Validation les information d'utilisateur et modifier
     *
     * @param email
     * @param password
     *
     */
    authentificationSignIn(signIn: any, type: string): Observable<any> {
        if (signIn === null || signIn === undefined) {
            throw new Error('Required parameter User was null or undefined.');
        }
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('post', `${this.api_url}/api/${type}/sign-in`,
            {
                body: signIn,
                headers: headers
            })
            .pipe(
                tap(({token, refreshToken}) => {
                    localStorage.setItem('token', token);
                    localStorage.setItem('refreshToken', refreshToken);
                })
            );
    }

    updateUser(user: User): void {
        this.currentUserSubject.next(user);
        this.updateNavigation();
    }

    logout(): void {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        this.currentUserSubject.next(null);
    }

    refreshToken(): Observable<any> {
        const headers = new HttpHeaders();
        return this.httpClient.request<any>('post', `${this.api_url}/api/authentification/refreshtoken`,
            {
                body: {refreshToken: localStorage.getItem('refreshToken')},
                headers: headers,
            });
    }

    isAuthorized(): boolean {
        return !!this.currentUserValue;
    }

    validateSignature(token: string): Observable<any> {
        if (token === null || token === undefined) {
            throw new Error('Required parameter User was null or undefined.');
        }
        const queryParameters = new HttpParams();
        const headers = new HttpHeaders();
        return this.httpClient.request<any>('put', `${this.api_url}/api/authentification/confirm/ngsign/${token}`,
            {
                headers: headers,
            }
        );
    }

    /**
     * Permettant de retourner les informations d'ordre disciplinaire de lutilisateur
     */
    getDisciplinaryOrderByUser(publicId: string, type: string): Observable<any> {
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('get', `${this.api_url}/api/${type}/disciplinaryOrder/byId/${publicId}`,
            {
                headers: headers,
            }
        );
    }

    /**
     * Permettant d'ajouter un order disciplinaire à un lutilisateur ou société
     */
    addDisciplinaryOrder(order: DisciplinaryOrder, publicId: string, type: string): Observable<any> {
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('post', `${this.api_url}/api/${type}/disciplinaryOrder/add/${publicId}`,
            {
                headers: headers,
                body: order
            }
        );
    }

    /**
     * Permettant de modifier un order disciplinaire à un lutilisateur ou société
     */
    modifyDisciplinaryOrder(publicId: string, type: string): Observable<any> {
        const headers = new HttpHeaders();

        return this.httpClient.request<any>('put', `${this.api_url}/api/user/disciplinaryOrder/reActive/${type}/${publicId}`,
            {
                headers: headers
            }
        );
    }

}
